import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Recommendations } from "components/Recommendations";
import { MarketingServicesForm } from "components/ServiceRequestForms/MarketingServicesForm";
import { Footer } from "components/Footer";
import { StickyHeader } from "components/StickyHeader";

import { ReactComponent as MarketingServicesSVG } from "../../images/svgs/product-pages/marketing-services.svg";
import { ReactComponent as MarketingServicesSVGM } from "../../images/svgs/product-pages-mobile/marketing-services.svg";
import MarketingServicesBannerPNG from "../../images/marketing-services-banner.png";

const scrollTo = (anchor: string) => {
    // We're using a function instead of anchor tags because we need to account for the sticky header.
    const element = document.getElementById(anchor);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 96,
            behavior: "smooth",
        });
    }
};

export const MarketingServicesRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "MarketingServices",
        },
    });

    return (
        <>
            <StickyHeader
                overviewOnClick={() => scrollTo("marketing-services-overview")}
                offeringsOnClick={() => scrollTo("marketing-services-offerings")}
                requestOnClick={() => scrollTo("marketing-services-request")}
            >
                <MarketingServicesSVG height={36} width={36} className="mr-8px" />
                <h4 className="mb-0 mr-16px">Marketing Services</h4>
            </StickyHeader>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Marketing Services
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col className="d-none d-md-block">
                        <Card style={{ maxHeight: "245px" }} className="overflow-hidden">
                            <Card.Img
                                src={MarketingServicesBannerPNG}
                                style={{ objectFit: "scale-down", objectPosition: "top right", maxHeight: "300px" }}
                            />
                            <Card.ImgOverlay className="p-32px d-flex">
                                <MarketingServicesSVG width={85} height={85} />
                                <div className="ml-32px">
                                    <h1>Marketing Services</h1>
                                    <p className="lead mb-24px">
                                        Unlock revenue growth through our omnichannel marketing expertise
                                    </p>
                                    <Button onClick={() => scrollTo("marketing-services-request")}>
                                        Request Service
                                    </Button>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                    <Col className="d-md-none px-0">
                        <Card className="p-24px">
                            <Card.Title className="d-flex justify-content-start align-items-center w-100">
                                <MarketingServicesSVGM width={36} height={36} />
                                <h1 className="m-4px">Marketing Services</h1>
                            </Card.Title>
                            <Card.Body className="p-0">
                                <p className="mb-24px">
                                    Unlock revenue growth through our omnichannel marketing expertise
                                </p>
                                {/* link */}
                                <Button className="w-100" onClick={() => scrollTo("marketing-services-request")}>
                                    Request Service
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-48px" id="marketing-services-overview">
                    <Col>
                        <h2 className="my-24px">Overview</h2>
                        <p className="lead mb-0">
                            Cart.com helps ambitious brands – whether startups or globally recognized - drive greater
                            revenue by developing and implementing data-driven cross-channel market strategies proven to
                            work.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white mb-48px">
                <Container className="py-32px">
                    <Row id="marketing-services-offerings">
                        <Col>
                            <h2 className="mb-24px">Service Offerings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Search Engine Optimization</h3>
                                    <p className="mb-0">
                                        Informed by customer and competitive data, our team of SEO experts help you
                                        increase your page rank across relevant to search terms by leveraging and
                                        integrating technical, on-page, and off-page approaches.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Integrated Media Management</h3>
                                    <p className="mb-0">
                                        Let us implement data-driven, performance and organic media strategies across
                                        channels including search and social, traditional media, and influencer and
                                        affiliate programs.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>eCRM and Lifecycle Management</h3>
                                    <p className="mb-0">
                                        Unlock incremental revenue at a high conversion rate and ROAS with your
                                        first-party data. We analyze and segment your customer file to execute robust
                                        multichannel retargeting and loyalty programs that can extract greater LTV out
                                        of existing audiences.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px mb-lg-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Channel Management</h3>
                                    <p className="mb-0">
                                        Let our team manage your direct-to-consumer website as well as your third-party
                                        digital shopping channels such as eBay, Amazon, Walmart, Google Shopping and
                                        many more. Synchronize your data to your direct-to-consumer site and create a
                                        single source of truth to easily manage your expansion into third party
                                        marketplaces.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4}>
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Marketing Intelligence</h3>
                                    <p className="mb-0">
                                        Our in-house data scientists and business intelligence experts collect, analyze,
                                        segment, and characterize customer and competitive data to optimize existing
                                        marketing strategies and identify new ones. We layer in third party data sets
                                        and use various statistical techniques to sharpen audience targeting across
                                        media, geographies, demographics, and psychographic dimensions.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row id="marketing-services-request">
                    <Col>
                        <h2>Service Request</h2>
                        <p className="lead">Please fill out the below form to request the service.</p>
                    </Col>
                </Row>
                <Row className="mb-48px">
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <MarketingServicesForm />
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <h2 className="mb-32px">Recommendations</h2>
                        <Recommendations pageToOmit="/marketing-services" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
