import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Recommendations } from "components/Recommendations";
import { OrderFulfillmentForm } from "components/ServiceRequestForms/OrderFulfillmentForm";
import { Footer } from "components/Footer";
import { StickyHeader } from "components/StickyHeader";
import { ImageGallery } from "components/ImageGallery";

import { ReactComponent as OrderFulfillmentSVG } from "../../images/svgs/product-pages/order-fulfillment.svg";
import { ReactComponent as OrderFulfillmentSVGM } from "../../images/svgs/product-pages-mobile/order-fulfillment.svg";
import OrderFulfillmentBannerPNG from "../../images/order-fulfillment-banner.png";

const scrollTo = (anchor: string) => {
    // We're using a function instead of anchor tags because we need to account for the sticky header.
    const element = document.getElementById(anchor);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 96,
            behavior: "smooth",
        });
    }
};

export const OrderFulfillmentRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "OrderFulfillment",
        },
    });

    return (
        <>
            <StickyHeader
                overviewOnClick={() => scrollTo("order-fulfillment-overview")}
                offeringsOnClick={() => scrollTo("order-fulfillment-offerings")}
                requestOnClick={() => scrollTo("order-fulfillment-request")}
            >
                <OrderFulfillmentSVG height={36} width={36} className="mr-8px" />
                <h4 className="mb-0 mr-16px">Order Fulfillment</h4>
            </StickyHeader>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Order Fulfillment
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-96px">
                    <Col className="d-none d-md-block">
                        <Card style={{ maxHeight: "245px" }} className="overflow-hidden">
                            <Card.Img
                                src={OrderFulfillmentBannerPNG}
                                style={{ objectFit: "scale-down", objectPosition: "top right", maxHeight: "300px" }}
                            />
                            <Card.ImgOverlay className="p-32px d-flex">
                                <OrderFulfillmentSVG width={85} height={85} />
                                <div className="ml-32px">
                                    <h1>Order Fulfillment</h1>
                                    <p className="lead mb-24px">
                                        Ship products faster with our nationwide fulfillment network.
                                    </p>
                                    <Button onClick={() => scrollTo("order-fulfillment-request")}>
                                        Request Service
                                    </Button>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                    <Col className="d-md-none px-0">
                        <Card className="p-24px">
                            <Card.Title className="d-flex justify-content-start align-items-center w-100">
                                <OrderFulfillmentSVGM width={36} height={36} />
                                <h1 className="m-4px">Order Fulfillment</h1>
                            </Card.Title>
                            <Card.Body className="p-0">
                                <p className="mb-24px">Ship products faster with our nationwide fulfillment network.</p>
                                {/* link */}
                                <Button className="w-100" onClick={() => scrollTo("order-fulfillment-request")}>
                                    Request Service
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="my-48px">
                    <ImageGallery />
                </Row>
                <Row className="mb-48px" id="order-fulfillment-overview">
                    <Col>
                        <h2 className="my-24px">Overview</h2>
                        <p className="lead mb-0">
                            Cart.com&apos;s Order Fulfillment equips brands of all sizes with the same ecommerce
                            infrastructure and national footprint as some of the world&apos;s largest retailers. Our
                            fulfillment centers pick, pack, and ship for direct-to-consumer and business-to-business
                            brands and achieve 1-2 day delivery across the continental U.S.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white mb-48px">
                <Container className="py-32px">
                    <Row id="order-fulfillment-offerings">
                        <Col>
                            <h2 className="mb-24px">Service Offerings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Nationwide Fulfillment</h3>
                                    <p className="mb-0">
                                        Deliver to your customers faster by leveraging our nationwide fulfillment
                                        network. Get packages to your customers in 1 to 2 business days in the
                                        continental U.S. while managing shipping costs through our strategically
                                        distributed fulfillment centers.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Pick, Pack, and Ship</h3>
                                    <p className="mb-0">
                                        We offer flexible pick, pack, and shipping pricing that scales with your
                                        business. Whether you&apos;re an ecommerce startup or an established brand, or
                                        whether you&apos;re a D2C or B2B business, our tech-enabled order fulfillment
                                        capabilities accommodate your needs.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Order Management</h3>
                                    <p className="mb-0">
                                        Brands view aggregate and SKU-level order data, on-hand inventory information,
                                        and inbound and outbound shipments all in a single, easy-to-navigate dashboard.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px mb-lg-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Fulfillment by Amazon (FBA) Restock </h3>
                                    <p className="mb-0">
                                        For Amazon-centric brands, our FBA restocking, storage, and prep services enable
                                        us to receive and hold your FBA-bound inventory until it&apos;s ready to ship to
                                        Amazon. We go the extra mile through our inspection and labeling services to
                                        ensure FBA compliance while decreasing the time until inventory is available for
                                        purchase.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4}>
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Inventory Storage</h3>
                                    <p className="mb-0">
                                        With several fulfillment centers distributed across the United States, we
                                        provide brands convenient long-term storage solutions at competitive rates. Our
                                        IT-enabled facilities boast best-in-class security and monitoring measures so
                                        you can trust your inventory is safe.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row id="order-fulfillment-request">
                    <Col>
                        <h2>Service Request</h2>
                        <p className="lead">Please fill out the below form to request the service.</p>
                    </Col>
                </Row>
                <Row className="mb-48px">
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <OrderFulfillmentForm />
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <h2 className="mb-32px">Recommendations</h2>
                        <Recommendations pageToOmit="/order-fulfillment" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
