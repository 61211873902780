import React, { ReactElement } from "react";

export const Footer = (): ReactElement => (
    <footer className="d-flex flex-row justify-content-center align-items-center mb-32px mt-auto">
        <a
            href="https://cart.com"
            target="_blank"
            rel="noreferrer"
            className="h4 mx-2 my-3 my-lg-0 mx-md-4 text-muted-base font-weight-normal"
        >
            &copy; 2021 Cart.com
        </a>
        <a
            href="https://www.cart.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
            className="h4 mx-2 my-3 my-lg-0 mx-md-4 text-muted-base font-weight-normal"
        >
            Terms of Service
        </a>
        <a
            href="https://www.cart.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="h4 mx-2 my-3 my-lg-0 mx-md-4 text-muted-base font-weight-normal"
        >
            Privacy Policy
        </a>
    </footer>
);
