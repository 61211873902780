import React, { ReactElement } from "react";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { switcherHome, switcherItems } from "constants/switcherConstants";

import styles from "./MobileSwitcher.module.scss";

type MobileSwitcherProps = {
    open: boolean;
};

const switcherLinks = switcherItems.map((switcherItem) => (
    <Link
        key={switcherItem.service}
        to={switcherItem.url}
        className="dropdown-item font-weight-bold font-family-gilroy d-flex align-items-center"
    >
        <switcherItem.image height={24} width={24} className="mr-8px" />
        {switcherItem.service}
    </Link>
));

export function MobileSwitcher({ open }: MobileSwitcherProps): ReactElement {
    const navStyle = open ? `${styles.open} ${styles.mobileNav}` : `${styles.mobileNav}`;

    return (
        <div className={navStyle}>
            <Link
                to={switcherHome.url}
                className="dropdown-item font-weight-bold font-family-gilroy mb-3 d-flex align-items-center"
            >
                <switcherHome.image height={20} width={20} className="mr-3 rounded svg-muted-base" />
                {switcherHome.service}
            </Link>

            <Dropdown.Header>Products & Services</Dropdown.Header>
            {switcherLinks}
        </div>
    );
}
