import React, { ReactElement, useEffect, useState } from "react";
import { Card, Row, Button, Carousel, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// SVGs //
import { ReactComponent as OnlineStoreSVG } from "images/svgs/product-pages/online-store.svg";
import { ReactComponent as OrderFulfillmentSVG } from "images/svgs/product-pages/order-fulfillment.svg";
import { ReactComponent as UnifiedAnalyticsSVG } from "images/svgs/product-pages/unified-analytics.svg";
import { ReactComponent as MarketingServiceSVG } from "images/svgs/product-pages/marketing-services.svg";
import { ReactComponent as CustomerServiceSVG } from "images/svgs/product-pages/customer-service.svg";
import { ReactComponent as BusinessPlanningSVG } from "images/svgs/product-pages/business-planning.svg";
import { ReactComponent as DataScienceSVG } from "images/svgs/product-pages/data-science.svg";
import { ReactComponent as ChevronRightSVG } from "images/svgs/chevron-right.svg";
import { ReactComponent as ChevronLeftSVG } from "images/svgs/chevron-left.svg";

import "./Recommendations.css";

type PageURL =
    | "/online-store"
    | "/order-fulfillment"
    | "/unified-analytics"
    | "/marketing-services"
    | "/customer-service"
    | "/business-planning"
    | "/data-science";

type CardType = {
    title: string;
    description: string;
    image: ReactElement;
    action: string;
    page: PageURL;
};

const cardContent: CardType[] = [
    {
        title: "Online Store",
        description: "Build your online store with the best tools and services.",
        image: <OnlineStoreSVG height={33} width={33} />,
        action: "Add",
        page: "/online-store",
    },
    {
        title: "Marketing Services",
        description: "Find and win more customers with data-driven marketing services and automation.",
        image: <MarketingServiceSVG height={33} width={33} />,
        action: "Request",
        page: "/marketing-services",
    },
    {
        title: "Customer Service",
        description: "Cultivate lifetime fans by giving your customers top-tier multichannel support.",
        image: <CustomerServiceSVG height={33} width={33} />,
        action: "Request",
        page: "/customer-service",
    },
    {
        title: "Order Fulfillment",
        description: "Ship products faster with our nationwide fulfillment network.",
        image: <OrderFulfillmentSVG height={33} width={33} />,
        action: "Request",
        page: "/order-fulfillment",
    },
    {
        title: "Unified Analytics",
        description:
            "Experience end-to-end analytics across your shopper journey as you attract and fulfill buyer demand.",
        image: <UnifiedAnalyticsSVG height={33} width={33} />,
        action: "Request",
        page: "/unified-analytics",
    },
    {
        title: "Business Planning",
        description: "Data-driven growth planning for multichannel ecommerce.",
        image: <BusinessPlanningSVG height={33} width={33} />,
        action: "Request",
        page: "/business-planning",
    },
    {
        title: "Data Science",
        description:
            "Leverage machine learning and automation to acquire, convert, and fulfill shopper demand at scale.",
        image: <DataScienceSVG height={33} width={33} />,
        action: "Request",
        page: "/data-science",
    },
];

type RecommendationsProps = {
    pageToOmit: PageURL;
    className?: string;
    style?: React.CSSProperties;
};

export const Recommendations = ({ pageToOmit, className, style }: RecommendationsProps): ReactElement => {
    const [carouselIndex, setCarouselIndex] = React.useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        // Bootstrap uses min-width for media queries.
        // We're matching them and negating the event.matches below to avoid a bug where widths of exactly 992px don't trigger changes.
        const mobileMediaQuery = window.matchMedia("(min-width: 992px)");

        // Set the initial state.
        setShowMobile(!mobileMediaQuery.matches);

        // Create an event listener so that we can remove it in our cleanup to avoid memory leaks.
        const mobileMediaQueryHandler = (event) => {
            setShowMobile(!event.matches);
            // Fixes a bug where swapping from the mobile carousel items to desktop items shows nothing if the index is outside the range of the number of desktop items.
            setCarouselIndex(0);
        };

        mobileMediaQuery.addEventListener("change", mobileMediaQueryHandler);

        return () => {
            // Cleanup Function
            mobileMediaQuery.removeEventListener("change", mobileMediaQueryHandler);
        };
    }, []);

    const recommendationCards = cardContent
        .map((card) => {
            if (card.page === pageToOmit) {
                return null;
            }

            return (
                <Col key={card.page} lg={4} md={12}>
                    <Card className="shadow-sm p-24px h-100">
                        <Card.Body className="p-0 d-flex flex-column align-items-start">
                            <Card.Title className="mb-16px d-flex justify-content-start align-items-center w-100">
                                {card.image}

                                <h3 className="mb-0 ml-8px">{card.title}</h3>

                                <Link to={card.page} component={Button} className="btn-sm ml-auto d-none d-xl-block">
                                    {card.action}
                                </Link>
                            </Card.Title>
                            <Card.Text className="mb-16px">{card.description}</Card.Text>
                            <Link to={card.page} component={Button} className="btn-sm d-xl-none mt-auto">
                                {card.action}
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            );
        })
        .filter((card) => card !== null);

    return (
        <Carousel
            className={`${className}`}
            style={style}
            activeIndex={carouselIndex}
            onSelect={(event) => setCarouselIndex(event)}
            interval={null}
            indicators={false}
            prevIcon={
                <Card className="shadow-lg border position-absolute d-none d-md-block" style={{ left: -20 }}>
                    <ChevronLeftSVG height={28} width={28} className="p-6px" />
                </Card>
            }
            nextIcon={
                <Card className="shadow-lg border position-absolute d-none d-md-block" style={{ right: -20 }}>
                    <ChevronRightSVG height={28} width={28} className="p-6px" />
                </Card>
            }
        >
            {showMobile
                ? recommendationCards.map((card) => (
                      <Carousel.Item>
                          <Row>{card}</Row>
                      </Carousel.Item>
                  ))
                : [
                      // Put these in an array because wrapping them with a fragment didn't work.
                      <Carousel.Item>
                          <Row>{recommendationCards.slice(0, 3)}</Row>
                      </Carousel.Item>,
                      <Carousel.Item>
                          <Row>{recommendationCards.slice(3, 6)}</Row>
                      </Carousel.Item>,
                  ]}
        </Carousel>
    );
};
