import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, ReactElement, useState } from "react";
import { Button, Card, Col, Container, Row, Image, Figure } from "react-bootstrap";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Footer } from "../components/Footer";
import { Solutions, Services } from "../data/SolutionsAndServices";
import { SolutionService } from "../components/SolutionService";
import Image1 from "../images/carousel/image1.png";
import Image2 from "../images/carousel/image2.png";

import MobileImage1 from "../images/carousel/mobileimage1.png";
import MobileImage2 from "../images/carousel/mobileimage2.png";

import "./HomeRoute.scss";

export const HomeRoute = ({ ACStoreName, ACStoreURL }: { ACStoreName?: string; ACStoreURL?: string }): ReactElement => {
    const { user } = useAuth0();

    const userMetadata = user["https://cart.com/user_metadata"];
    const appMetadata = user["https://cart.com/app_metadata"];
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "Home",
        },
    });
    // Genereate index (0-1) for random hero banner
    const bannerIndex = Math.round(Math.random());
    // prettier-ignore
    const [servicesRequested] = useState(
        Object.values(appMetadata?.leadStatus?.creativeServices ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.digitalMarketing ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.orderFulfillment ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.customerService ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.paymentProcessing ?? false).includes(true) ||
        Object.values(appMetadata?.leadStatus?.businessFinancing ?? false).includes(true)
    );

    const caption1 = {
        header: "Expand your reach and drive acquisition",
        paragraph:
            "Leverage Cart.com's proven strategic, marketing, and creative expertise to fill the top of your sales funnel and acquire more customers.",
        link: (
            <Link id="Banner1" to="/marketing-services" component={Button} className="btn-secondary">
                Learn More
            </Link>
        ),
        image: Image1,
        mobileimage: MobileImage1,
    };

    const caption2 = {
        header: "Boost conversion and drive revenue growth while increasing efficiency",
        paragraph:
            "Turbocharge your direct-to-consumer site and efficiently convert visitors into customers while driving up AOV, ROAS, and LTV.",
        link: (
            <Link id="Banner2" to="/marketing-services" component={Button} className="btn-secondary">
                Learn More
            </Link>
        ),
        image: Image2,
        mobileimage: MobileImage2,
    };

    const bannerArray = [caption1, caption2];

    const bannerCaption = (
        <Figure.Caption
            className="text-muted-darker text-left h-100 p-32px d-flex flex-column align-items-start justify-content-between position-absolute"
            style={{ top: 0, left: 0, width: "50%" }}
        >
            {/* Handles lg bp, as header/text had too much overflow on a carousel item */}
            <h1 className="d-none d-lg-block d-xl-none" style={{ fontSize: "26px" }}>
                {bannerArray[bannerIndex].header}
            </h1>
            <p className="lead d-none d-lg-block d-xl-none" style={{ fontSize: "16px" }}>
                {bannerArray[bannerIndex].paragraph}
            </p>
            {/* */}
            <h1 className="d-none d-xl-block">{bannerArray[bannerIndex].header}</h1>
            <p className="lead d-none d-xl-block">{bannerArray[bannerIndex].paragraph}</p>
            {bannerArray[bannerIndex].link}
        </Figure.Caption>
    );
    return (
        <>
            <Container className="my-64px p-0">
                {/* Header */}
                <Row className="mb-48px mx-0">
                    <Col>
                        {(ACStoreURL && ACStoreName) || userMetadata?.connectedStore?.url || servicesRequested ? (
                            <h1 className="mb-0">Welcome, {user.name}</h1>
                        ) : (
                            <h1 className="mb-0">Hey {user.given_name}, Welcome to Cart.com!</h1>
                        )}
                    </Col>
                </Row>
                {/* Hero Banner */}
                <Row className="mx-0 mb-32px d-none d-lg-block">
                    <Col>
                        <Figure className="position-relative overflow-hidden">
                            <Figure.Image
                                src={bannerArray[bannerIndex].image}
                                className="image-fluid"
                                fluid
                                alt="Banner"
                            />
                            {bannerCaption}
                        </Figure>
                        <Image />
                    </Col>
                </Row>
                {/* Mobile Hero */}
                <Row className="d-lg-none mx-0 mb-32px">
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={bannerArray[bannerIndex].mobileimage} />
                            <Card.Body className="p-24px">
                                <h2 className="m-0 pb-16px">{bannerArray[bannerIndex].header}</h2>
                                <Card.Text className="lead pb-16px">{bannerArray[bannerIndex].paragraph}</Card.Text>
                                {bannerArray[bannerIndex].link}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Featured Solutions Web */}
                <Row className="mx-0 mb-32px d-none d-sm-block">
                    <Col>
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h2 className="m-0">Featured Solutions</h2>
                            </Card.Header>
                            <Card.Body className="p-32px">
                                <Row noGutters>
                                    {Solutions.map((sol) => (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            className="p-16px d-flex flex-column align-items-start"
                                        >
                                            <SolutionService
                                                SVG={sol.svg}
                                                Title={sol.title}
                                                Description={sol.description}
                                                Route={sol.route}
                                                ACStoreName={ACStoreName}
                                                ACStoreURL={ACStoreURL}
                                                key={sol.index}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Featured Solutions Mobile */}
                <Row className="mx-0 mb-32px d-block d-sm-none">
                    <Col className="px-0">
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h3 className="m-0">Featured Solutions</h3>
                            </Card.Header>
                            <Card.Body
                                className="p-32px d-block d-sm-none no-scrollbar"
                                style={{ overflowX: "scroll" }}
                            >
                                <Row noGutters style={{ minWidth: "800px" }}>
                                    {Solutions.map((s) => (
                                        <Col className="p-16px d-flex flex-column align-items-start mr-16px border rounded-lg">
                                            <SolutionService
                                                SVG={s.mobileSVG}
                                                Title={s.title}
                                                Description={s.description}
                                                Route={s.route}
                                                ACStoreName={ACStoreName}
                                                ACStoreURL={ACStoreURL}
                                                key={s.index}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Featured Services Web */}
                <Row className="mx-0 d-none d-sm-block">
                    <Col>
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h2 className="m-0">Featured Services</h2>
                            </Card.Header>
                            <Card.Body className="p-32px">
                                <Row noGutters>
                                    {Services.map((serv) => (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            className="p-16px d-flex flex-column align-items-start"
                                        >
                                            <SolutionService
                                                SVG={serv.svg}
                                                Title={serv.title}
                                                Description={serv.description}
                                                Route={serv.route}
                                                ACStoreName={ACStoreName}
                                                ACStoreURL={ACStoreURL}
                                                key={serv.index}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Featured Services Mobile */}
                <Row className="mx-0 d-block d-sm-none">
                    <Col className="px-0">
                        <Card className="shadow">
                            <Card.Header className="bg-white border-bottom px-32px py-16px">
                                <h3 className="m-0">Featured Services</h3>
                            </Card.Header>
                            <Card.Body
                                className="p-32px d-block d-sm-none no-scrollbar"
                                style={{ overflowX: "scroll" }}
                            >
                                <Row noGutters style={{ minWidth: "1330px" }}>
                                    {Services.map((serv) => (
                                        <Col
                                            className="p-16px d-flex flex-column align-items-start mr-16px border rounded-lg"
                                            style={{ fontSize: "14px" }}
                                        >
                                            <SolutionService
                                                SVG={serv.mobileSVG}
                                                Title={serv.title}
                                                Description={serv.description}
                                                Route={serv.route}
                                                ACStoreName={ACStoreName}
                                                ACStoreURL={ACStoreURL}
                                                key={serv.index}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
