import React, { ReactElement } from "react";
import { Nav, Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ReactComponent as ProfileSidebarProfileSVG } from "../images/svgs/profile-sidebar-profile.svg";
import { ReactComponent as ProfileSidebarSecuritySVG } from "../images/svgs/profile-sidebar-security.svg";
import { ReactComponent as ProfileSidebarOnlineStoreSVG } from "../images/svgs/profile-sidebar-onlinestore.svg";

import { SettingsProfile } from "./SettingsRoutes/SettingsProfile";
import { SettingsSecurity } from "./SettingsRoutes/SettingsSecurity";
import { SettingsOnlineStore } from "./SettingsRoutes/SettingsOnlineStore";

import "./SettingsRoute.scss";

export const SettingsRoute = ({ OnlineStoreURL }: { OnlineStoreURL: string }): ReactElement => {
    const { url, path } = useRouteMatch();
    const location = useLocation();
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "Settings",
        },
    });
    return (
        <Container fluid>
            <Row className="row-min-height-responsive">
                {/* Side Nav */}
                <Nav
                    as={Col}
                    md={3}
                    xl={2}
                    variant="pills"
                    className="d-none d-md-block flex-column px-8px pt-32px shadow-lg font-weight-bold"
                >
                    <NavLink
                        exact
                        to={url}
                        className="d-flex align-items-center nav-link side-nav-link mb-6px svg-muted-base"
                        activeClassName="active svg-muted-lightest"
                    >
                        <ProfileSidebarProfileSVG className="mr-12px" />
                        <span>Profile</span>
                    </NavLink>

                    <NavLink
                        exact
                        to={`${url}/security`}
                        className="d-flex align-items-center nav-link side-nav-link mb-6px svg-muted-base"
                        activeClassName="active svg-muted-lightest"
                    >
                        <ProfileSidebarSecuritySVG className="mr-12px svg-muted-base" />
                        <span>Security</span>
                    </NavLink>

                    {OnlineStoreURL && (
                        <NavLink
                            exact
                            to={`${url}/online-store`}
                            className="d-flex align-items-center nav-link side-nav-link mb-6px svg-muted-base"
                            activeClassName="active svg-muted-lightest"
                        >
                            <ProfileSidebarOnlineStoreSVG className="mr-12px svg-muted-base" />
                            <span>Online Store</span>
                        </NavLink>
                    )}
                </Nav>
                <Col className="px-32px mb-64px">
                    <Breadcrumb className="my-16px user-select-none">
                        <NavLink exact to="/" className="breadcrumb-item">
                            Home
                        </NavLink>
                        <Breadcrumb.Item active className="font-weight-bold">
                            {/* Refactor this when more routes are added to the app settings. */}
                            {location.pathname === "/settings/security" ? "Security" : "Profile"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    {/* Mobile */}
                    <Nav className="d-md-none mb-24px">
                        <NavLink
                            exact
                            to={url}
                            className="font-weight-bold"
                            activeClassName="pb-4px text-primary-base border-bottom border-primary-base"
                        >
                            <span>Profile</span>
                        </NavLink>
                        <NavLink
                            exact
                            to={`${url}/security`}
                            className="font-weight-bold ml-16px"
                            activeClassName="pb-4px text-primary-base border-bottom border-primary-base"
                        >
                            <span>Security</span>
                        </NavLink>
                        {OnlineStoreURL && (
                            <NavLink
                                exact
                                to={`${url}/online-store`}
                                className="font-weight-bold ml-16px"
                                activeClassName="pb-4px text-primary-base border-bottom border-primary-base"
                            >
                                <span>Online Store</span>
                            </NavLink>
                        )}
                    </Nav>
                    <Switch>
                        <Route exact path={path} component={SettingsProfile} />
                        <Route exact path={`${path}/security`} component={SettingsSecurity} />
                        <Route exact path={`${path}/online-store`}>
                            {OnlineStoreURL ? (
                                <SettingsOnlineStore OnlineStoreURL={OnlineStoreURL} />
                            ) : (
                                <Redirect to="/settings" />
                            )}
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </Container>
    );
};
