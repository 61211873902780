import React, { ReactElement, useState } from "react";
import { Dropdown, Navbar, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useOnClickOutside } from "hooks";
import { ServiceSwitcher } from "./ServiceSwitcher";
import { ReactComponent as CartLogoSVG } from "../images/svgs/cartlogo.svg";
import { ReactComponent as DropDownArrow } from "../images/svgs/dropdown-arrow.svg";
import { MobileSwitcher } from "./MobileSwitcher";
import { ShadowOverlay } from "./ShadowOverlay";

import "./NavBar.scss";

export const NavBar = (): ReactElement => {
    const { user, logout } = useAuth0();

    const [accountOpen, setAccountOpen] = useState(false);

    const [modalIsShowing, setmodalIsShowing] = useState(false);

    const handleShow = () => setmodalIsShowing(!modalIsShowing);

    const ref = React.useRef();
    useOnClickOutside(ref, () => setmodalIsShowing(false));

    // const triggerSafariRepaint = () => {
    //     // Safari has a bug where it doesn't repaint properly.
    //     // This function is designed to force trigger a repaint to fix the
    //     // drop shadow and ghosting issue on Safari browsers.
    //     if (navigator.vendor.match(/apple/i)) {
    //         const siteHeader = document.getElementsByTagName("nav")[0];
    //         if (siteHeader) {
    //             siteHeader.style.display = "none";
    //             // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //             siteHeader.offsetHeight; // no need to store this anywhere, the reference is enough
    //             siteHeader.style.display = "flex";
    //         }
    //     }
    // };

    const burgerClass = modalIsShowing ? "border-0 py-0 navbar-toggler-icon-active" : "border-0 py-0";

    return (
        <Navbar
            variant="light"
            bg="white"
            expand="md"
            className="align-items-stretch"
            style={{ zIndex: 1000, filter: "drop-shadow(0px 4px 8px rgba(32, 33, 36, 0.1))" }}
        >
            <OverlayTrigger show={modalIsShowing} overlay={ShadowOverlay}>
                <Navbar.Toggle className={burgerClass} onClick={handleShow} ref={ref} />
            </OverlayTrigger>
            <MobileSwitcher open={modalIsShowing} />

            <Link to="/" className="px-24px">
                <Navbar.Brand>
                    <CartLogoSVG width="71.35" height="40" />
                </Navbar.Brand>
            </Link>

            {/* Services Drop Down Menu */}
            <div className="d-none d-md-block">
                <ServiceSwitcher />
            </div>

            {/* Account Drop Down Menu */}
            <Dropdown
                show={accountOpen}
                onToggle={() => {
                    setAccountOpen(!accountOpen);
                    // triggerSafariRepaint();
                }}
                className="ml-auto"
            >
                <Dropdown.Toggle as="button" bsPrefix="btn" className="h-100 d-flex align-items-center">
                    {/* Desktop Button */}
                    <strong className="d-none d-md-block">{user.name}</strong>
                    <DropDownArrow className="d-none d-md-block ml-12px" />
                    {/* Mobile Button */}
                    <OverlayTrigger
                        overlay={ShadowOverlay}
                        // only show when the profile card is open and window size is less than tablet bp
                        show={accountOpen && window.innerWidth < 768}
                        container={document.getElementById("root")}
                    >
                        <div
                            className={`d-flex d-md-none h-24px w-24px justify-content-center align-items-center overflow-hidden rounded-circle ${
                                accountOpen ? "bg-primary-base" : "bg-muted-base"
                            } text-white line-height-1`}
                        >
                            {user.name[0]}
                        </div>
                    </OverlayTrigger>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    align="right"
                    className="p-0 overflow-hidden rounded-bottom"
                    style={{ marginTop: "1px", marginRight: "5px", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                >
                    <div className="text-center">
                        <img
                            src={user.picture}
                            alt="User Profile"
                            height={80}
                            width={80}
                            className="rounded-circle mt-24px mb-8px mx-64px"
                        />
                        <div className="mb-8px px-8px font-weight-bold">{user.name}</div>
                        <div className="mb-16px px-8px text-break">{user.email}</div>
                    </div>
                    <Link
                        to="/settings"
                        role="button"
                        className="btn btn-primary d-block mb-24px mx-64px"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => setAccountOpen(false)}
                    >
                        Manage Account
                    </Link>
                    <Dropdown.Divider className="my-0" />
                    <Dropdown.Item
                        onClick={() => logout({ returnTo: window.location.origin })}
                        className="text-center font-weight-bold rounded-0"
                    >
                        Sign Out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    );
};
