import React, { ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SolutionService = ({
    SVG,
    Title,
    Description,
    Route,
    ACStoreName,
    ACStoreURL,
}: {
    SVG: ReactElement;
    Title: string;
    Description: string;
    Route: string;
    ACStoreName: string;
    ACStoreURL: string;
}): ReactElement => {
    const { user } = useAuth0();
    const appMetadata = user["https://cart.com/app_metadata"];

    let enabled = false;
    let requested = false;

    //  Determine badge status for each service and solution
    //  This is more or less hardcoded for now, but can be made simpler by creating uniform property names in Auth0, and adding those
    //  property names to the Solution and Service interfaces in the SoultionsAndServices.tsx file
    switch (Title) {
        case "Online Store":
            if (ACStoreURL && ACStoreName) {
                enabled = true;
            }
            break;
        case "Order Fulfillment":
            if (appMetadata?.provisioningStatus?.wms) enabled = true;
            else if (appMetadata?.requestStatus?.orderFulfillment) requested = true;
            break;
        case "Unified Analytics":
            if (appMetadata?.provisioningStatus?.mia) enabled = true;
            break;
        case "Marketing Services":
            if (appMetadata?.requestStatus?.marketingServices) requested = true;
            break;
        case "Customer Service":
            if (appMetadata?.requestStatus?.customerService) requested = true;
            break;
        case "Business Planning":
            if (appMetadata?.requestStatus?.businessPlanning) requested = true;
            break;
        case "Data Science":
            if (appMetadata?.requestStatus?.dataScience) requested = true;
            break;
        default:
            break;
    }

    //  Enabled Badge markup
    const enabledBadge = (
        <Badge
            variant="success-lightest"
            className="text-success-darker d-inline-flex align-items-center small-font-size align-top"
            style={{ fontWeight: 500 }}
        >
            <span className="rounded bg-success w-8px h-8px mr-8px" />
            Enabled
        </Badge>
    );

    //  Requested Badge markup
    const requestedBadge = (
        <Badge
            variant="info-lightest"
            className="text-info-darker d-inline-flex align-items-center small-font-size align-top"
            style={{ fontWeight: 500 }}
        >
            Requested
        </Badge>
    );
    return (
        <>
            <div>
                {SVG}
                {enabled ? enabledBadge : requested && requestedBadge}
            </div>
            <div>
                <h3 className="mb-16px mr-24px d-inline-block">{Title}</h3>
            </div>
            <p className="mb-16px">{Description}</p>
            <Link to={Route} component={Button} className="mt-auto">
                {enabled ? "Launch" : "Get Started"}
            </Link>
        </>
    );
};
