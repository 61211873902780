import React, { ReactElement } from "react";

// z-index 1 less than the nav
export const ShadowOverlay = (): ReactElement => (
    <div
        style={{
            zIndex: 999,
            display: "block",
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.5)",
        }}
    />
);
