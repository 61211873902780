import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Recommendations } from "components/Recommendations";
import { DataScienceForm } from "components/ServiceRequestForms/DataScienceForm";
import { Footer } from "components/Footer";
import { StickyHeader } from "components/StickyHeader";

import { ReactComponent as BusinessPlanningSVG } from "../../images/svgs/product-pages/data-science.svg";
import { ReactComponent as BusinessPlanningSVGM } from "../../images/svgs/product-pages-mobile/data-science.svg";
import BusinessPlanningBannerPNG from "../../images/data-science-banner.png";

const scrollTo = (anchor: string) => {
    // We're using a function instead of anchor tags because we need to account for the sticky header.
    const element = document.getElementById(anchor);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 96,
            behavior: "smooth",
        });
    }
};

export const DataScienceRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "DataScience",
        },
    });

    return (
        <>
            <StickyHeader
                overviewOnClick={() => scrollTo("data-science-overview")}
                offeringsOnClick={() => scrollTo("data-science-offerings")}
                requestOnClick={() => scrollTo("data-science-request")}
            >
                <BusinessPlanningSVG height={36} width={36} className="mr-8px" />
                <h4 className="mb-0 mr-16px">Data Science</h4>
            </StickyHeader>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Data Science
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col className="d-none d-md-block">
                        <Card style={{ maxHeight: "245px" }} className="overflow-hidden">
                            <Card.Img
                                src={BusinessPlanningBannerPNG}
                                style={{ objectFit: "scale-down", objectPosition: "top right", maxHeight: "300px" }}
                            />
                            <Card.ImgOverlay className="p-32px d-flex">
                                <BusinessPlanningSVG width={85} height={85} />
                                <div className="ml-32px">
                                    <h1>Data Science</h1>
                                    <p className="lead mb-24px">Fuel brand performance with AI-enabled insights. </p>
                                    <Button onClick={() => scrollTo("data-science-request")}>Request Service</Button>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                    <Col className="d-md-none px-0">
                        <Card className="p-24px">
                            <Card.Title className="d-flex justify-content-start align-items-center w-100">
                                <BusinessPlanningSVGM width={36} height={36} />
                                <h1 className="m-4px">Data Science</h1>
                            </Card.Title>
                            <Card.Body className="p-0">
                                <p className="mb-24px">
                                    Fuel brand performance with AI-enabled insights.
                                    <br />
                                    <br />
                                </p>
                                {/* link */}
                                <Button className="w-100" onClick={() => scrollTo("customer-service-request")}>
                                    Request Service
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-48px" id="data-science-overview">
                    <Col>
                        <h2 className="my-24px">Overview</h2>
                        <p className="lead mb-0">
                            Cart.com&apos;s team of expert data scientists are at your service, giving brands the
                            statistical advantage to scale your business. Our processes surface the most impactful
                            problems in your omni-channel value chain and deliver actionable insights to improve
                            business strategy, growth, and profitability.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white mb-48px">
                <Container className="py-32px">
                    <Row id="data-science-offerings">
                        <Col>
                            <h2 className="mb-24px">Service Offerings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Omni-Channel Analysis</h3>
                                    <p className="mb-0">
                                        Whether you&apos;re a DTC brand or multi-faceted enterprise, we create a Unified
                                        Analytics hub across all sales channels and logistics networks to discover your
                                        true performance. Our proprietary segmentation processes are then tailored to
                                        fit your marketing strategies and business verticals, giving you unprecedented
                                        granularity.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Demand Forecasting</h3>
                                    <p className="mb-0">
                                        Knowing the future demand for your products unlocks new opportunities that drive
                                        better profits. Using our forecasting and optimization models, brands can better
                                        meet demand of popular items without slow-moving stock draining your storage
                                        costs. With less money tied up in inventory, more of your investments go towards
                                        research and development or for increasing ad spend to drives sales.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Campaign Optimization</h3>
                                    <p className="mb-0">
                                        Pricing is both an art and a science, and need not be static and must be
                                        increasingly personalized and adaptive. Our team includes pricing economists who
                                        can work with you to analyze competitive pricing, measure price elasticity of
                                        demand, run price sensitivity analyses, and model revenue and various price
                                        points and pricing models to dial in a pricing strategy that drives top line and
                                        bottom line growth.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px mb-lg-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Buyer Journey Modeling</h3>
                                    <p className="mb-0">
                                        Brands have normalized low conversion, believing higher traffic is required to
                                        drive more sales. Our Signals Repository, along with proven Google Tag Manager
                                        strategies, gives brands the visibility of where fallout is occurring in the
                                        shopping process. These insights are then organized in prescriptive actions to
                                        improve the buyer’s journey, resulting in higher sales.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-24px mb-md-0">
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>Customer Experience Analysis</h3>
                                    <p className="mb-0">
                                        Sentiment and emotion are powerful predictors in customer loyalty and retention,
                                        but often manifest themselves outside of the purchasing process. Our expertise
                                        in separating actionable signals from noisy sources like reviews, customer
                                        service channels, and social media helps brands create interaction models to
                                        increase Customer Lifetime Value. We also uncover emerging competitor, product
                                        safety, and brand reputation risks to identify new opportunities for performance
                                        improvement.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4}>
                            <Card className="h-100">
                                <Card.Header className="bg-primary-base p-0 h-8px" />
                                <Card.Body className="bg-muted-lighter">
                                    <h3>We&apos;ve Seen it All</h3>
                                    <p className="mb-0">
                                        Have a problem that’s difficult to solve? We weren’t always this awesome.
                                        Through the years, we’ve battle-tested our approaches and built accelerators to
                                        hack your biggest data problems. Basically, if data were cool…consider us Miles
                                        Davis. We’re so proud of it, we’ll even show you how we did it! Give us a shout
                                        and we promise to help drive your results.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row id="data-science-request">
                    <Col>
                        <h2>Service Request</h2>
                        <p className="lead">Please fill out the below form to request the service.</p>
                    </Col>
                </Row>
                <Row className="mb-48px">
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <DataScienceForm />
                    </Col>
                </Row>
                <Row className="mb-64px">
                    <Col>
                        <h2 className="mb-32px">Recommendations</h2>
                        <Recommendations pageToOmit="/data-science" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
