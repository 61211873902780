import React, { ReactElement, useEffect, useState } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import {
    Services,
    switcherHome,
    switcherItems,
    getSwitcherItemByService,
    getSwitcherItemByURL,
} from "constants/switcherConstants";
import { ReactComponent as DropDownArrow } from "../images/svgs/dropdown-arrow.svg";
import { ReactComponent as HomeSVG } from "../images/svgs/home.svg";

export const ServiceSwitcher = (): ReactElement => {
    const location = useLocation();

    const [servicesOpen, setServicesOpen] = useState(false);
    const [activeService, setActiveService] = useState(Services.HOME);

    const theService = getSwitcherItemByService(activeService);

    const renderServiceSVG = () => {
        if (activeService === Services.HOME) {
            return <HomeSVG height={20} width={20} className="mr-12px svg-muted-base" />;
        }

        return <theService.image height={20} width={20} className="mr-8px" />;
    };

    useEffect(() => {
        const theServiceFromURL = getSwitcherItemByURL(location.pathname);
        setActiveService(theServiceFromURL.service);
    }, [location]);

    const switcherLinks = switcherItems.map((switcherItem) => (
        <Link
            key={switcherItem.service}
            to={switcherItem.url}
            onClick={() => {
                setActiveService(switcherItem.service);
                setServicesOpen(false);
            }}
            className="dropdown-item font-weight-bold font-family-gilroy"
        >
            <switcherItem.image height={24} width={24} className="mr-8px" />
            {switcherItem.service}
        </Link>
    ));

    const staticSwitcher = (
        <div className="p-8px">
            <Dropdown.Header>Products & Services</Dropdown.Header>

            {switcherLinks}

            <Dropdown.Header className="mt-16px">Admin Console</Dropdown.Header>

            <Link
                to={switcherHome.url}
                onClick={() => {
                    setActiveService(switcherHome.service);
                    setServicesOpen(false);
                }}
                className="dropdown-item font-weight-bold font-family-gilroy"
            >
                <switcherHome.image height={24} width={24} className="mr-16px rounded svg-muted-base" />
                {switcherHome.service}
            </Link>
        </div>
    );

    return (
        <Dropdown
            show={servicesOpen}
            onToggle={() => {
                setServicesOpen(!servicesOpen);
                // triggerSafariRepaint();
            }}
            className="d-flex align-items-center h-100"
        >
            <Dropdown.Toggle
                as="button"
                bsPrefix="btn"
                className="d-flex align-items-center bg-white border rounded border-muted-light text-muted-dark px-16px py-12px"
                style={{ height: "3.143rem", minWidth: "19.5rem" }}
            >
                {renderServiceSVG()}
                <span className="mr-auto">{activeService}</span>
                <DropDownArrow className="ml-8px" />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="p-0 shadow-none"
                style={{ top: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, minWidth: "19.5rem" }}
            >
                <Dropdown.Toggle
                    as="button"
                    bsPrefix="btn"
                    className="d-flex align-items-center text-muted-dark px-16px py-12px w-100"
                    style={{ height: "4.571rem" }}
                >
                    {renderServiceSVG()}
                    <span className="mr-auto">{activeService}</span>

                    {/* mb-n2x makes the badge look slightly more centered */}

                    <Badge
                        variant="primary-lightest"
                        className="text-primary-darker font-weight-normal font-family-proxima mb-n2px"
                        style={{ fontSize: "0.85714rem" }}
                    >
                        selected
                    </Badge>

                    <DropDownArrow className="ml-8px" transform="scale(-1)" />
                </Dropdown.Toggle>

                <Dropdown.Divider className="mt-0 mx-8px" />

                {staticSwitcher}
            </Dropdown.Menu>
        </Dropdown>
    );
};
