import { ReactComponent as HomeSVG } from "../images/svgs/home.svg";
import { ReactComponent as OnlineStoreSVG } from "../images/svgs/service-switcher/online-store.svg";
import { ReactComponent as OrderFulfillmentSVG } from "../images/svgs/service-switcher/order-fulfillment.svg";
import { ReactComponent as UnifiedAnalyticsSVG } from "../images/svgs/service-switcher/unified-analytics.svg";
import { ReactComponent as MarketingServicesSVG } from "../images/svgs/service-switcher/marketing-services.svg";
import { ReactComponent as CustomerServiceSVG } from "../images/svgs/service-switcher/customer-service.svg";
import { ReactComponent as BusinessPlanningSVG } from "../images/svgs/service-switcher/business-planning.svg";
import { ReactComponent as DataScienceSVG } from "../images/svgs/service-switcher/data-science.svg";
import { ReactComponent as GrowthCapitalSVG } from "../images/svgs/service-switcher/growth-capital.svg";

export enum Services {
    HOME = "Home",
    ONLINE_STORE = "Online Store",
    ORDER_FULFILLMENT = "Order Fulfillment",
    UNIFIED_ANALYTICS = "Unified Analytics",
    MARKETING_SERVICES = "Marketing Services",
    CUSTOMER_SERVICE = "Customer Service",
    BUSINESS_PLANNING = "Business Planning",
    DATA_SCIENCE = "Data Science",
    GROWTH_CAPITAL = "Growth Capital",
}

type SwitcherItemType = {
    service: Services;
    image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    url: string;
};

export const switcherHome = {
    service: Services.HOME,
    image: HomeSVG,
    url: "/",
};

export const switcherItems: SwitcherItemType[] = [
    {
        service: Services.ONLINE_STORE,
        image: OnlineStoreSVG,
        url: "/online-store",
    },
    {
        service: Services.ORDER_FULFILLMENT,
        image: OrderFulfillmentSVG,
        url: "/order-fulfillment",
    },
    {
        service: Services.UNIFIED_ANALYTICS,
        image: UnifiedAnalyticsSVG,
        url: "/unified-analytics",
    },
    {
        service: Services.MARKETING_SERVICES,
        image: MarketingServicesSVG,
        url: "/marketing-services",
    },
    {
        service: Services.CUSTOMER_SERVICE,
        image: CustomerServiceSVG,
        url: "/customer-service",
    },
    {
        service: Services.BUSINESS_PLANNING,
        image: BusinessPlanningSVG,
        url: "/business-planning",
    },
    {
        service: Services.DATA_SCIENCE,
        image: DataScienceSVG,
        url: "/data-science",
    },
    {
        service: Services.GROWTH_CAPITAL,
        image: GrowthCapitalSVG,
        url: "/growth-capital",
    },
];

export function getSwitcherItemByService(service: Services): SwitcherItemType {
    if (service === Services.HOME) {
        return switcherHome;
    }
    return switcherItems.filter((switcherItem) => switcherItem.service === service)[0];
}

export function getSwitcherItemByURL(url: string): SwitcherItemType {
    if (url === "/") {
        return switcherHome;
    }
    const [theItem] = switcherItems.filter((switcherItem) => url.startsWith(switcherItem.url));
    return theItem || switcherHome;
}
