import React, { ReactElement } from "react";
import TagManager from "react-gtm-module";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Footer } from "components/Footer";

export const GrowthCapitalRoute = (): ReactElement => {
    TagManager.dataLayer({
        dataLayer: {
            event: "pageview",
            route: "GrowthCapital",
        },
    });

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb className="my-16px user-select-none">
                            <NavLink exact to="/" className="breadcrumb-item">
                                Home
                            </NavLink>
                            <Breadcrumb.Item active className="font-weight-bold">
                                Growth Capital
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-48px" id="business-planning-overview">
                    <Col>
                        <div className="w-embed w-iframe">
                            <iframe
                                title="clearco"
                                referrerPolicy="same-origin"
                                loading="eager"
                                src="https://my.clearbanc.com/partners/Cart-Com/signup-capital?logo_options=both"
                                height="800"
                                width="100%"
                                style={{ border: "1px solid #f7f7f7" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};
