/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ReactComponent as OnlineStoreSVG } from "../images/svgs/home-route/online-store.svg";
import { ReactComponent as OrderFulfillmentSVG } from "../images/svgs/home-route/order-fulfillment.svg";
import { ReactComponent as UnifiedAnalyticsSVG } from "../images/svgs/home-route/unified-analytics.svg";
import { ReactComponent as MarketingServicesSVG } from "../images/svgs/home-route/marketing-services.svg";
import { ReactComponent as CustomerServiceSVG } from "../images/svgs/home-route/customer-service.svg";
import { ReactComponent as BusinessPlanningSVG } from "../images/svgs/home-route/business-planning.svg";
import { ReactComponent as DataScienceSVG } from "../images/svgs/home-route/data-science.svg";
import { ReactComponent as GrowthCapitalSVG } from "../images/svgs/home-route/growth-capital.svg";
import { ReactComponent as MobileOnlineStoreSVG } from "../images/svgs/home-route-mobile/online-store.svg";
import { ReactComponent as MobileOrderFulfillmentSVG } from "../images/svgs/home-route-mobile/order-fulfillment.svg";
import { ReactComponent as MobileUnifiedAnalyticsSVG } from "../images/svgs/home-route-mobile/unified-analytics.svg";
import { ReactComponent as MobileMarketingServicesSVG } from "../images/svgs/home-route-mobile/marketing-services.svg";
import { ReactComponent as MobileCustomerServiceSVG } from "../images/svgs/home-route-mobile/customer-service.svg";
import { ReactComponent as MobileBusinessPlanningSVG } from "../images/svgs/home-route-mobile/business-planning.svg";
import { ReactComponent as MobileDataScienceSVG } from "../images/svgs/home-route-mobile/data-science.svg";
import { ReactComponent as MobileGrowthCapitalSVG } from "../images/svgs/home-route-mobile/growth-capital.svg";
//  Define necessary fields for solutions and services items on Home Page
interface Solution {
    index: number;
    svg: any;
    mobileSVG: any;
    title: string;
    description: string;
    route: string;
}
interface Service {
    index: number;
    svg: any;
    mobileSVG: any;
    title: string;
    description: string;
    route: string;
}

export const Solutions: Solution[] = [
    {
        index: 0,
        svg: <OnlineStoreSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileOnlineStoreSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Online Store",
        description: "I want to build a brand new online store using Cart.com's online storefront software.",
        route: "/online-store",
    },
    {
        index: 1,
        svg: <OrderFulfillmentSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileOrderFulfillmentSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Order Fulfillment",
        description: "Ship products faster with our nationwide fulfillment network.",
        route: "/order-fulfillment",
    },
    {
        index: 2,
        svg: <UnifiedAnalyticsSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileUnifiedAnalyticsSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Unified Analytics",
        description:
            "Experience end-to-end analytics across your shopper journey as you attract and fulfill buyer demand.",
        route: "/unified-analytics",
    },
];

export const Services: Service[] = [
    {
        index: 0,
        svg: <MarketingServicesSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileMarketingServicesSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Marketing Services",
        description: "Expert marketing services for omnichannel sales growth.",
        route: "/marketing-services",
    },
    {
        index: 1,
        svg: <CustomerServiceSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileCustomerServiceSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Customer Service",
        description: "Increase satisfaction and sales with a white-labeled customer service team.",
        route: "/customer-service",
    },
    {
        index: 2,
        svg: <BusinessPlanningSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileBusinessPlanningSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Business Planning",
        description: "Data-driven growth planning for multichannel ecommerce.",
        route: "/business-planning",
    },
    {
        index: 3,
        svg: <DataScienceSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileDataScienceSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Data Science",
        description:
            "Learn about current and upcoming capabilities leveraging machine learning and automation to acquire, convert, and fulfill shopper demand at scale.",
        route: "/data-science",
    },
    {
        index: 4,
        svg: <GrowthCapitalSVG width={80} height={80} className="mb-32px mr-16px" />,
        mobileSVG: <MobileGrowthCapitalSVG width={80} height={80} className="mb-32px mr-16px" />,
        title: "Growth Capital",
        description:
            "Access equity-free capital to help fund your marketing & inventory costs. Investments are designed to help you scale your company while maitaining ownership & control.",
        route: "/growth-capital",
    },
];
